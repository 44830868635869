import React from 'react';
import './Landing.css';
import './LandingMobile.css';


function About() {

    const freso_about_01 = require('../images/bg7.png'); 
    const freso_about_02 = require('../images/bg2.png'); 

    return (

        <>
            <div className="wrap-about" id="about-us">
                <div className="about-heading">
                    <img src={freso_about_01}  alt="FresoTech About"/>
                    <h2>About</h2>
                </div>

                <div className="about-container">
                    <div className="about-content-1">
                        <div className="about-cont-1-item-01">
                            <img src={freso_about_02}  alt="FresoTech About"/>
                        </div>
                        
                        <div className="about-cont-1-item-02">
                            <h3>FRESO TECH is a fast-growing Web & App Development company based in Kuala Lumpur (KL), Malaysia. </h3>
                            <p>We specialize in providing professional website design & development services to small & medium-sized enterprises (SMEs) and corporate companies. With our expertise, we help our clients make their brand stand out, optimize their digital presence, and subsequently grow their business online.</p>
                            <h4>What’s more? </h4>
                            <p>We customize all our website designs, no template website, no duplication, and all about your unique brand. Our team day to day mission is to ensure the web design & web development experience and meet expectations. We ensure the economic growth of our clients who are doing digital transformation or bringing their business online and world-class.</p>
                        </div>
                    </div>

                    <div className="about-content-2">
                        <div className="about-cont-2-item-01">
                            <h4>VISION</h4>
                            <p>We Transform Your Business from OFFLINE to ONLINE</p>
                        </div>

                        <div className="about-cont-2-item-01">
                            <h4>MISSION</h4>
                            <p>To Grow Talents in Web Design & Development <br />
                                To Bring The Best Solutions To Our Clients </p>
                        </div>

                        <div className="about-cont-2-item-01">
                            <h4>CREATE VALUE</h4>
                            <p>We do more than just developing websites. <br/>
                                We create values, for your business and your customers.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default About;

