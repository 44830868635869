import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import About from '../About';
import Services from '../Services';
import Testimonials from '../Testimonials';
import ContactUs from '../ContactUs';

function Home() {
    return (
        <> 
            <HeroSection />
            <About />
            <Services />
            <Testimonials />
            <ContactUs />
        </>
    ); 
}

export default Home;