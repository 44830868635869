import React, { useState } from 'react';
import './Landing.css';
import './LandingMobile.css';
import Carousel from 'react-bootstrap/Carousel';
import { BsChatSquareQuote } from 'react-icons/bs';

function Testimonials() {

    const freso_testi_01 = require('../images/bg7.png'); 
    const testi_icon_1 = require('../images/icon7.png'); 
    const testi_icon_2 = require('../images/icon8.png'); 
    const testi_icon_3 = require('../images/icon9.png'); 
    const testi_contact_4 = require('../images/bg5.png');  

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (

        <>
            <div className="wrap-testi" id="testimonials">
                <div>
                    <div className="testi-heading">
                        <div>
                            <img src={freso_testi_01}  alt="FresoTech Testimonial"/>
                        </div>
                        <div>
                            <h2>TESTIMONIALS</h2>
                            <p>What Clients are saying about us</p>
                        </div>
                    </div>

                    <div className="testi-container">
                        <div className="testi-content-1">
                            <div className="testi-cont-1-item-01">

                                <Carousel activeIndex={index} onSelect={handleSelect}>
                                    <Carousel.Item>
                                        <BsChatSquareQuote />
                                        <p className="testi-carou-text-01">The team was proactive in providing updates and answers to the client's inquiries during the development. Highly recommended services from the Freso team</p>
                                        <div class="Rectangle"></div>
                                        <div className="testi-carou-cont-02">
                                            <img className="testi-icon-img"  src={testi_icon_1} alt="FresoTech Testimonial"  />  
                                            <p>WASH LA! SDN. BHD.</p> 
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <BsChatSquareQuote />
                                        <p className="testi-carou-text-01">The communication was great, with constant updates, quick responses to any questions. This is the best team I have ever worked with before as they are always capable of giving us prompt reply and services whenever needed.</p>
                                        <div class="Rectangle"></div>
                                        <div className="testi-carou-cont-02">
                                            <img className="testi-icon-img"  src={testi_icon_2} alt="FresoTech Testimonial"  />  
                                            <p>BCBOT International</p> 
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <BsChatSquareQuote />
                                        {/* <p className="testi-carou-quote">"</p> */}
                                        <p className="testi-carou-text-01">Thanks for the professional suggestion during the project initial phrase from the presentation, strategy, branding ideas. Overall, we are very satisfied with the results and the team's expertise</p>
                                        <div class="Rectangle"></div>
                                        <div className="testi-carou-cont-02">
                                            <img className="testi-icon-img"  src={testi_icon_3} alt="FresoTech Testimonial"  />   
                                            <p>TC Lam Fruit Shop</p>
                                        </div>
                                    </Carousel.Item>
                                    
                                </Carousel>                          
                            </div> 
                        </div>

                        <div className="testi-content-2">
                            <div className="testi-cont-2-item-01">
                                <img src={testi_contact_4}  alt="FresoTech Appointment"></img>
                            </div>

                            <div className="testi-cont-2-item-02">
                                <h3>Become one of our success stories!</h3>
                                <p>Schedule an appointment with us.</p>
                                <a href="mailto:enquiry@fresco-tech.com">Make Appointment Now</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default Testimonials;

