import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import './Navbar.css';

export const NavLink = styled(Link)`
  &.active {
    color: #00112a;
    font-weight: bold;
  }

`;

const Navbar = () => {

    const freso_logo = require('../images/Freso-logo-02.png');

    const [click, setClick] = useState(false);
    const [setButton] = useState(true);
    // const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    //mobile btn
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    return (
        <>
        
            <div className="navbar">

                {/* <NavMenu > */}

                <div className="navbar-container">
                    
                    <NavLink to='/' className="navbar-logo"  target='_parent'>
                        <img src={freso_logo} width="120px" alt="FresoTech logo"/>
                    </NavLink>

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <NavLink to='/' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Home
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#about-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                About
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#services' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Services
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#testimonials' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Testimonials
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#contact-us' className='nav-links' target="_parent" onClick={closeMobileMenu} >
                                Contact Us
                            </NavLink>
                        </li>

                    </ul>

                    {/* <a href="/" target="_parent" ><img src={freso_logo} className="nav-logo-mb" alt="FresoTech logo"/></a> */}

                </div> {/*  close navbar-container */}
                {/* </NavMenu> */}
                
            </div>
            
        </>

    )

};

export default Navbar;
