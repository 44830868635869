import React from 'react';
import './Footer.css';


function Footer() {

    const freso_footer_01 = require('../images/Freso-logo-02.png'); 

    return (

        <>
            <div className="wrap-footer" >
                <div className="footer-container">
                    <div className="footer-content-1">
                        <img src={freso_footer_01} alt="FresoTech Web & App Development"/>
                        <div className="footer-cont-1-item-01">
                            <h3>FRESO TECH SDN. BHD. (1431484-X)</h3>
                            <a href="tel:+60392868080" target="_blank" rel="noopener noreferrer">+603 9286 8080</a>
                        </div>
                        
                        <div className="footer-cont-1-item-02">
                            <p>Address: V05-03-06 Signature 1, Lingkaran SV Sunway Velocity</p>
                        </div>
                    </div>

                    <div className="footer-content-2">
                        <p>© 2022 Freso Tech Sdn. Bhd. All rights reserved.</p>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Footer;

