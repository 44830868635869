import React from 'react';
import './Landing.css';
import './LandingMobile.css';


function Services() {

    const freso_serv_01 = require('../images/bg7.png'); 
    const serv_icon_1 = require('../images/icon3.png'); 
    const serv_icon_2 = require('../images/icon1.png'); 
    const serv_icon_3 = require('../images/icon5.png'); 
    const serv_icon_4 = require('../images/icon2.png'); 
    const serv_icon_5 = require('../images/icon6.png');
    const serv_icon_6 = require('../images/icon4.png');  

    return (

        <>
            <div className="wrap-serv" id="services">
                <div className="serv-heading">
                    <img src={freso_serv_01} alt="FresoTech Services"/>
                    <h2>Services</h2>
                </div>

                <div className="serv-container">
                    <div className="serv-content-1">
                        <div className="serv-cont-1-item-01">
                            <img src={serv_icon_1}  alt="FresoTech Services"/>
                            <h3>Web Development</h3>
                            <p>Achieve greater brand engagement with engaging and intuitive web designs that are mobile responsive. </p>
                        </div>
                        <div className="serv-cont-1-item-01">
                            <img src={serv_icon_2}  alt="FresoTech Services"/>
                            <h3>Technology Provision </h3>
                            <p>Combining emerging technologies (like Cloud Computing, Software-as-a-Service, Social Networks, and Mobility) to help your business to meet the end goals and benefit from the power of technology innovation.</p>
                        </div>
                        <div className="serv-cont-1-item-01">
                            <img src={serv_icon_3}  alt="FresoTech Services"/>
                            <h3>Mobile App Deployment</h3>
                            <p>Android & IOS app design and development, app store optimization, app marketing, app maintenance, and app design.</p>
                        </div>
                        <div className="serv-cont-1-item-01">
                            <img src={serv_icon_4}  alt="FresoTech Services"/>
                            <h3>I.T Consulting Service</h3>
                            <p>Provide IT Strategy, to support the efficient and effective achievement of their business objectives, ensuring the end-to-end IT security across the organization.</p>
                        </div>
                        <div className="serv-cont-1-item-01">
                            <img src={serv_icon_5}  alt="FresoTech Services"/>
                            <h3>CRM Solutions</h3>
                            <p>Enjoy the convenience of a hassle-free CRM that is made for your business.</p>
                        </div>
                        <div className="serv-cont-1-item-01">
                            <img src={serv_icon_6}  alt="FresoTech Services"/>
                            <h3>Hosting Solutions</h3>
                            <p>We conceptualize and create bespoke websites to tailor to the needs of your company and your audiences - improving the efficacy and probability of a desired action on your online business.</p>
                        </div>
                    </div>

                    <div className="serv-content-2">
                        <div className="serv-cont-2-item">
                            <h3>Our service values are always based upon a strong foundation — PASSION.</h3>
                        </div>
                        <div className="serv-cont-2-item">
                            <div className="serv-cont-2-item-01">
                                <h3>239+ </h3>
                                <p>Satisfied Clients</p>
                            </div>
                            <div className="serv-cont-2-item-01">
                                <h3>169+ </h3>
                                <p>Websites Developed</p>
                            </div>
                            <div className="serv-cont-2-item-01">
                                <h3>182+ </h3>
                                <p>Projects Completed</p>
                            </div>
                            <div className="serv-cont-2-item-01">
                                <h3>20+ </h3>
                                <p>Team Size</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Services;

