import React from 'react';
import './HeroSection.css';


function HeroSection() {

    // const freso_hero_01 = require('../images/bg1.png'); 

    return (

        <>
            <div className="wrap-hero">
                <div className="hero-container">
                    <h1>We are <br/> Freso Tech.</h1>
                    <p>A Web & App Development company</p>
                    <a role="button" href="/#contact-us">Get A Quote</a>
                </div>
            </div>
        </>
    );
}

export default HeroSection;

