import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* Switch = Routes in react-router-dom v6 */
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Footer from './components/Footer';
import NotFoundPage from './components/pages/NotFoundPage';


function App() {
  return (
    <>
    <Router>

      <Navbar />  

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <Footer />
      
    </Router>
    
    </>
  );
}

export default App;
