import React from 'react';
import './Landing.css';
import './LandingMobile.css';


function ContactUs() {

    const testi_contact_1 = require('../images/bg7.png'); 
    // const testi_contact_2 = require('../images/bg6.png'); 

    return (

        <>
            <div className="wrap-contact" id="contact-us">
                <div className="contact-heading">
                    <img src={testi_contact_1} alt="FresoTech Contact" />
                    <h2>Contact Us</h2>
                </div>

                <div className="contact-container">
                    <div className="contact-content-1">
                        <div className="contact-cont-1-item-01">
                            <h3>Start your project with us!</h3>
                            <h4>Let us work together to make something impactful.</h4>

                            <div className="contact-cont-1-item-desc-01">
                                <p>Any ideas or epic projects in mind?</p>
                                <p>Let’s Connect!</p>
                            </div>

                            <div className="contact-cont-1-item-desc-02">
                                <p>Send us a message at </p>
                                <a href="mailto:enquiry@fresco-tech.com">enquiry@fresco-tech.com</a>
                                
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default ContactUs;

